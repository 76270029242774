import { Injectable } from '@angular/core';
import { KeycloakService as KeycloakServiceAngular } from 'keycloak-angular';


@Injectable({
  providedIn: 'root'
})
export class KeycloakService {

  constructor(private readonly keycloak: KeycloakServiceAngular) { }

  isLoggedIn(): boolean {
    return this.keycloak.isLoggedIn();
  }
  logout(): void {
    this.keycloak.logout();
  }

  getUserProfile(): any {
    return this.keycloak.loadUserProfile();
  }

  getAcessToken(): any {
    return this.keycloak.getToken();
  }
  
}
