import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { jwtDecode } from "jwt-decode";
import { TokenDecode } from '../interfaces/token-decode.interface';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    private readonly keycloak: KeycloakService) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {

    let authenticated = this.keycloak.getKeycloakInstance().authenticated;
    if (!authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    } else {
      this.keycloak.getToken().then(res => {
        const token = res
        const decoded: TokenDecode = jwtDecode(token);
        sessionStorage.setItem('tokenHFC', token)
        sessionStorage.setItem('mailHFC', decoded.email)
        sessionStorage.setItem('fullnameHFC', decoded.name)
      });
    }
    return true
  }

}
