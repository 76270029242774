import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AuthStatus } from '../interfaces';

export const isNotAuthenticatedGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService)
  const router = inject(Router)

  const status = authService.authStatus()
  if (status === AuthStatus.authenticated) {
    router.navigateByUrl('/dashboard/categories/list')
    return false
  }

  return true

};
