import { Injectable, computed, signal } from '@angular/core';
import { enviroments } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { LoginResponse, User, AuthStatus } from '../interfaces';
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl: string = enviroments.baseUrl;

  private _currentUser = signal<User | null>(null)
  private _authStatus = signal<AuthStatus>(AuthStatus.checking)

  //Esto va  para que se ocupe fuera del servicio
  public currentUser = computed(() => this._currentUser())
  public authStatus = computed(() => this._authStatus())


  constructor(private http: HttpClient) { this.verifyToken().subscribe(res => console.log(res)) }

  login(nombreUsr: string, password: string): Observable<boolean> {
    const url = `${this.baseUrl}/auth/loguin`
    const body = {
      nombreUsr,
      password,
      sistema: "SIS_ACAI_LIGHT-1.0"
    }
    return this.http.post<LoginResponse>(url, body)
      .pipe(
        tap(({ detalle, codigo }) => {
          if (codigo === 200) {
            this._currentUser.set(detalle)
            this._authStatus.set(AuthStatus.authenticated)
            const decoded = jwtDecode(detalle.token);
            localStorage.setItem('userData', JSON.stringify(decoded))
            localStorage.setItem('token', detalle.token)
          }
        }),
        map(({ codigo }) => {
          if (codigo === 200) { return true } else { return false }
        }),
        catchError(err => {
          console.log(err)
          throwError(() => "Algo salio mal ")
          return of(false)
        })

      )


  }


  verifyToken(): Observable<boolean> {
    const url = `${this.baseUrl}/auth/validate`
    const token = localStorage.getItem('token')
    if (!token) {
      this.logOut()
      return of(false)
    } //si no existe token

    let params = new HttpParams()
      .set('token', token)

    return this.http.post<boolean>(url, {}, { params: params })
      .pipe(
        map(res => {
          if (res) {
            this._authStatus.set(AuthStatus.authenticated)
            return res
          } else {
            this._authStatus.set(AuthStatus.notAuthenticated)
            return res
          }
        }),
        catchError(() => {
          this._authStatus.set(AuthStatus.notAuthenticated)
          return of(false)
        })
      )

  }

  logOut() {
    localStorage.clear()
    this._currentUser.set(null)
    this._authStatus.set(AuthStatus.notAuthenticated)
  }
}
