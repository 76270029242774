<div class="layout-topbar">
    <a class="layout-topbar-logo">
        <img src="assets/logoSamaBlanco.webp" style="height: 60px;" alt="Logo" alt="logo">
        <span class="text-base">Cliente</span>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu"
        [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">

        <button class="p-link layout-topbar-button" (click)="logOut()">
            <i class="pi pi-sign-out"></i>
            <span>Cerrar sesion</span>
        </button>

    </div>
</div>