import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { DividerModule } from 'primeng/divider';
import { SplitterModule } from 'primeng/splitter';
import localeEs from '@angular/common/locales/es';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { enviroments } from 'src/environments/environment';

registerLocaleData(localeEs);
function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
        keycloak.init({
            config: {
                url: enviroments.urlKeycloak,
                clientId: enviroments.clientId,
                realm: enviroments.realm
            },
            initOptions: {
                onLoad: 'check-sso',
            }
        });
}
@NgModule({
    declarations: [AppComponent],
    imports: [AppRoutingModule, AppLayoutModule, ToastModule, ButtonModule, KeycloakAngularModule,
        RippleModule, DividerModule, SplitterModule],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        MessageService,
        { provide: LOCALE_ID, useValue: 'es-ES' },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },

    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
