import { CanActivateFn, RouterModule } from '@angular/router';
import { NgModule, inject } from '@angular/core';
import { isNotAuthenticatedGuard } from './auth/guards/is-notAuthenticated.guard';
import { AuthGuard } from './authenticated/auth.guard';

const isAuthenticated: CanActivateFn = (route, state) => {
    return inject(AuthGuard).isAccessAllowed(route, state);
}


@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'user', loadChildren: () => import("./user-login/user-login.module").then(m => m.UserLoginModule), canActivate: [isAuthenticated]
            },

            {
                path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), canActivate: [isNotAuthenticatedGuard]
            },
            {
                path: '',
                loadChildren: () => import('./presentation-page/presentation-page.module').then(m => m.PresentationPageModule)
            },
            {
                path: 'tickets/:eveId/:idEmpresa',
                loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
            },
            {
                path: 'success-screen',
                loadChildren: () => import('./success-screen/success-screen.module').then(m => m.SuccessScreenModule)
            },
            { path: '**', redirectTo: '' }
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
