import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: MenuItem[] = [];

    constructor(public layoutService: LayoutService) { }

    ngOnInit() {
        this.model = [

            {
                label: 'Opciones',
                items: [
                    {
                        label: 'Mis pedidos y tickets', icon: 'pi pi-tags', routerLink: ['/user/dashboard/my-tickets'], routerLinkActiveOptions: { exact: false }
                    },
                    /*{ label: 'Información de usuario', icon: 'pi pi-user', routerLink: ['/user/dashboard/update-user/update'], routerLinkActiveOptions: { exact: false } },
                    { label: 'Contáctanos', icon: 'pi pi-phone', routerLink: ['/dashboard/history'], routerLinkActiveOptions: { exact: false } },
*/
                ]
            },
        ];
    }
}