export interface User {
    token: string;
    refreshToken: string;
    bearer: string;
    nombreUsuario: string;
    funcionalidades: Funcionalidade[];
    authorities: Authority[];
}

export interface Authority {
    authority: string;
}

export interface Funcionalidade {
    id: number;
    codigo: string;
    nombre: string;
    icono: string;
    iconoMovil: string;
    descripcion: null | string;
    orden: number;
    ordenGrafico: number | null;
}
